import { Navigate, Route, Routes } from 'react-router-dom';
import GalleryFull from '../GalleryFull/GalleryFull';
import MenuEat from '../MenuEat/MenuEat';
import RestTemplate from '../RestTemplate/RestTemplate';
import './App.css';

export default function App() {
  return (
    <Routes>
      <Route path='/' element={<Navigate to='/RU'/>}/>
      <Route path='/:lang' element={
        <RestTemplate
          HeaderImgArr={[require('../StaticElement/Image/PoedemPoedim/1.webp'), require('../StaticElement/Image/PoedemPoedim/2.webp'),require('../StaticElement/Image/PoedemPoedim/3.webp')]}
          MenuPhoto={require('../StaticElement/Image/PoedemPoedim/MenuBlock.webp')}
          GalleryKitchen={[require('../StaticElement/Image/PoedemPoedim/Kitchen_1.webp'), require('../StaticElement/Image/PoedemPoedim/Kitchen_2.webp'), require('../StaticElement/Image/PoedemPoedim/poedem poedimLogo.png'), require('../StaticElement/Image/PoedemPoedim/Kitchen_3.webp'), require('../StaticElement/Image/PoedemPoedim/Kitchen_4.webp'), require('../StaticElement/Image/PoedemPoedim/Kitchen_5.webp')]}
          GalleryInterior={[require('../StaticElement/Image/PoedemPoedim/Interior_1.webp'), require('../StaticElement/Image/PoedemPoedim/Interior_2.webp'), require('../StaticElement/Image/PoedemPoedim/Interior_3.webp'), require('../StaticElement/Image/PoedemPoedim/Interior_4.webp'), require('../StaticElement/Image/PoedemPoedim/Interior_5.webp'), require('../StaticElement/Image/PoedemPoedim/poedem poedim.png'), require('../StaticElement/Image/PoedemPoedim/Interior_6.webp')]}
          ContactMap='https://yandex.ru/map-widget/v1/?um=constructor%3A4f58214da3202b37a8cf330e14cedfaa2478c4fb96a5da425ace58084388c870&amp;source=constructor'
          ContactTel='+375 29 105 6666'
          ContactMail='amizbel@mail.ru'
          Inst='https://instagram.com/poedem.poedim.restaurant?igshid=YmMyMTA2M2Y='
          Link='PoedemPoedim'
          Tour={true}
          Menu={true}
          Bar={false}
          TourImg={require('../StaticElement/Image/PoedemPoedim/tourimg.jpg')}
          TourSource='https://yandex.by/maps/org/poyedem_poyedim/210392788005/?ll=27.643627%2C53.914878&mode=search&panorama%5Bdirection%5D=51.141144%2C0.610055&panorama%5Bfull%5D=true&panorama%5Bpoint%5D=27.601692%2C53.969581&panorama%5Bspan%5D=115.679057%2C60.000000&sctx=ZAAAAAgCEAAaKAoSCXmu78NBjjtAES%2F6CtKM80pAEhIJdy6M9KL25T8RoWgewCK%2Fxj8iBQABAgQGKAA4AECdAUgBYjVyZWxldl9yYW5raW5nX2hlYXZ5X3JlbGV2X3NlcnBfZm9ybXVsYT0wLjY6bDNfZGM2Njg4NGI1cmVsZXZfcmFua2luZ19oZWF2eV9yZWxldl9zZXJwX2Zvcm11bGE9MC42OmwzX2RjNjY4ODRiNHJlbGV2X3JhbmtpbmdfaGVhdnlfY2xpY2tfbWFwc19mb3JtdWxhPTAuNDpmbWw4Njg0OTJiNHJlbGV2X3JhbmtpbmdfaGVhdnlfY2xpY2tfbWFwc19mb3JtdWxhPTAuNDpmbWw4Njg0OTJiNXJlbGV2X3JhbmtpbmdfaGVhdnlfcmVsZXZfbWFwc19mb3JtdWxhPTAuNjpsM19kYzY2ODg0YjVyZWxldl9yYW5raW5nX2hlYXZ5X3JlbGV2X21hcHNfZm9ybXVsYT0wLjY6bDNfZGM2Njg4NGIgYWRkX3NuaXBwZXQ9c2tpX2RhdGFfdG9fMW9yZy8xLnhiG2FkZF9zbmlwcGV0PWF2aWFfcHJpY2VzLzEueGIgYWRkX3NuaXBwZXQ9c2tpX2RhdGFfdG9fMW9yZy8xLnhiG2FkZF9zbmlwcGV0PWF2aWFfcHJpY2VzLzEueGIsYWRkX3NuaXBwZXQ9YnVzaW5lc3NfYXdhcmRzX2V4cGVyaW1lbnRhbC8xLnhiHGFkZF9zbmlwcGV0PW1haW5fYXNwZWN0cy8xLnhiHXJlbGV2PXNlYXJjaF90ZXh0X2V4cF9uYW1lPXYxYhJyZWxldj1yZWxldmdlbz0xNDliNHJlbGV2X3JhbmtpbmdfaGVhdnlfY2xpY2tfc2VycF9mb3JtdWxhPTAuNDpmbWw4Njg0OTJiNHJlbGV2X3JhbmtpbmdfaGVhdnlfY2xpY2tfc2VycF9mb3JtdWxhPTAuNDpmbWw4Njg0OTJiMHJlYXJyPXNjaGVtZV9Mb2NhbC9HZW91cHBlci9BZGRGaXhlZFRvcEdlb2pzb249MWI3cmVhcnI9c2NoZW1lX0xvY2FsL0dlby9SZXF1ZXN0VWdjRm9yUG9zc2libGVPd25lcnM9dHJ1ZWI3cmVhcnI9c2NoZW1lX0xvY2FsL0dlby9SZXF1ZXN0VWdjRm9yUG9zc2libGVPd25lcnM9dHJ1ZWoCYnmVAQAAAACdAc3MTD6gAQGoAQC9AX7mR7jCAQyl2IzjjwbSsOTaogHqAQDyAQD4AQCCAiTQv9C%2B0LXQtNC10Lwg0L%2FQvtC10LTQuNC8INC80LjQvdGB0LqKAgCSAgMxNTeaAgRzZXJw&sll=27.643627%2C53.914878&source=wizbiz_new_map_multi&sspn=0.431433%2C0.111672&text=%D0%BF%D0%BE%D0%B5%D0%B4%D0%B5%D0%BC%20%D0%BF%D0%BE%D0%B5%D0%B4%D0%B8%D0%BC%20%D0%BC%D0%B8%D0%BD%D1%81%D0%BA&z=12э'
          Relax='https://poedem-poedim.relax.by/'
        />
      }/>
      <Route path='/Menu:lang' element={<MenuEat/>}/>
      <Route path='/Gallery:lang' element={<GalleryFull/>}/>
    </Routes>
  );
}